import { render, staticRenderFns } from "./chugaoForzhongxinDetail.vue?vue&type=template&id=ba4fe0ba&scoped=true&"
import script from "./chugaoForzhongxinDetail.vue?vue&type=script&lang=js&"
export * from "./chugaoForzhongxinDetail.vue?vue&type=script&lang=js&"
import style0 from "./chugaoForzhongxinDetail.vue?vue&type=style&index=0&id=ba4fe0ba&scoped=true&lang=css&"
import style1 from "./chugaoForzhongxinDetail.vue?vue&type=style&index=1&id=ba4fe0ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba4fe0ba",
  null
  
)

export default component.exports